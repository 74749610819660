section {
  box-sizing: border-box;
  width: 100%;
  height: 101vh;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  padding: 50px;
  background: #ff0055;
}

section:nth-child(2) {
  background: #f108a4;
}

section:nth-child(3) {
  background: #bd19c8;
}

section:nth-child(4) {
  background: #0077ff;
}

section span {
  display: block;
  transform: translateX(-100px);
  opacity: 0;
}
