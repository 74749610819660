@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'PT Serif', serif, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.custom-chevron-down::before {
  border-style: solid;
	border-width: 0.15em 0.15em 0 0;
  border-color: #465580;
	content: '';
	display: block;
	height: 0.41em;
	width: 0.41em;
	transform: rotate(135deg);
}

.custom-chevron-up::before {
  border-style: solid;
	border-width: 0 0 0.15em 0.15em;
  border-color: #465580;
	content: '';
	display: block;
	height: 0.41em;
	width: 0.41em;
	transform: rotate(135deg);
}

.custom-chevron-parent:hover .custom-chevron-down::before {
  border-color: #ffffff;
}

.custom-chevron-parent:hover .custom-chevron-up::before {
  border-color: #ffffff !important;
}

.parent-icon-hover:hover .child-icon-hover {
  fill: #465580
}

.custom-shape-divider-bottom-1703353552 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1703353552 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 73px;
  transform: rotateY(180deg);
}


/* TEMP */
.comingsoon {
  animation: wiggle 2s linear infinite;
}

/* Keyframes */
@keyframes wiggle {
  0%, 7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-15deg);
  }
  20% {
    transform: rotateZ(10deg);
  }
  25% {
    transform: rotateZ(-10deg);
  }
  30% {
    transform: rotateZ(6deg);
  }
  35% {
    transform: rotateZ(-4deg);
  }
  40%, 100% {
    transform: rotateZ(0);
  }
}

.confetti-container {
  user-select: none;
  z-index: 10;
}
.confetti {
  position: fixed;
  left: 0;
  right: 0;
  display: flex;
  /* width: 600px; */
  /* height: 600px; */
  /* overflow: hidden; */
}

.confetti .square {
  width: 1rem;
  height: 1rem;
  background-color: var(--bg);
  transform: rotate(-140deg);
}

.confetti .rectangle {
  width: 1rem;
  height: 0.5rem;
  background-color: var(--bg);
}

.confetti .hexagram {
  width: 0;
  height: 0;
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
  border-bottom: 1rem solid var(--bg);
  position: relative;
}

.confetti .hexagram:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
  border-top: 1rem solid var(--bg);
  position: absolute;
  top: 0.33rem;
  left: -0.5rem;
}

.confetti .pentagram {
  width: 0rem;
  height: 0rem;
  display: block;
  margin: 0.5rem 0;
  border-right: 1rem solid transparent;
  border-bottom: 0.7rem solid var(--bg);
  border-left: 1rem solid transparent;
  transform: rotate(35deg);
  position: relative;
}
.confetti .pentagram:before {
  content: "";
  width: 0;
  height: 0;
  display: block;
  border-bottom: 0.8rem solid var(--bg);
  border-left: 0.3rem solid transparent;
  border-right: 0.3rem solid transparent;
  transform: rotate(-35deg);
  position: absolute;
  top: -0.45rem;
  left: -0.65rem;
}
.confetti .pentagram:after {
  content: "";
  width: 0rem;
  height: 0rem;
  display: block;
  border-right: 1rem solid transparent;
  border-bottom: 0.7rem solid var(--bg);
  border-left: 1rem solid transparent;
  transform: rotate(-70deg);
  position: absolute;
  top: 0.03rem;
  left: -1.05rem;
}

.confetti .dodecagram {
  background: var(--bg);
  width: 0.8rem;
  height: 0.8rem;
  position: relative;
}

.confetti .dodecagram:before {
  content: "";
  height: 0.8rem;
  width: 0.8rem;
  background: var(--bg);
  transform: rotate(30deg);
  position: absolute;
  top: 0;
  left: 0;
}
.confetti .dodecagram:after {
  content: "";
  height: 0.8rem;
  width: 0.8rem;
  background: var(--bg);
  transform: rotate(60deg);
  position: absolute;
  top: 0;
  left: 0;
}

.confetti .wavy-line {
  position: relative;
}
.confetti .wavy-line::after,
.confetti .wavy-line::before {
  content: "";
  height: 1rem;
  width: 8rem;
  background-size: 2rem 1rem;
  position: absolute;
  left: -9rem;
  transform: rotate(90deg);
}

.confetti .wavy-line::before {
  background-image: linear-gradient(
    45deg,
    transparent,
    transparent 50%,
    var(--bg) 50%,
    transparent 60%
  );
  top: 1rem;
}
.confetti .wavy-line::after {
  background-image: linear-gradient(
    -45deg,
    transparent,
    transparent 50%,
    var(--bg) 50%,
    transparent 60%
  );
}

.confetti i {
  width: 3rem;
  height: 3rem;
  margin: 0 0.2rem;
  animation-name: confetti;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: calc(70s / var(--speed));
}

.confetti i:nth-child(even) {
  transform: rotate(90deg);
}

@keyframes confetti {
  0% {
    transform: translateY(-100vh);
  }

  100% {
    transform: translateY(100vh);
  }
}